@font-face{
    font-family: Marvel-Regular;
    src: url('../fonts/Marvel/Marvel-Regular.ttf')
}
@font-face{
    font-family: Marvel-Italic;
    src: url('../fonts/Marvel/Marvel-Italic.ttf')
}
@font-face{
    font-family: Marvel-Bold;
    src: url('../fonts/Marvel/Marvel-Bold.ttf')
}
@font-face{
    font-family: Marvel-BoldItalic;
    src: url('../fonts/Marvel/Marvel-BoldItalic.ttf')
}

* {
    font-family: Marvel-Regular !important;
}
html {
    height: 100%;
    box-sizing: border-box;
}

body {
    height: 100%;
    margin: 0;
    color: white;
}

a, a:hover, a:focus, a:visited{
    text-decoration: none;
    border-bottom: none;
}
img {
    max-width: 100%;
}
h2 {
    color: #dd8abd;
}
.HomeContainer{
    background: linear-gradient(#1e8172, #12505f,#003c59);
}

/* ---- Banner ---- */
.PageHero{
    justify-content: center;
    width: 100%;
    max-width: 1440px;
    display: flex;
    text-align: center;
    margin: auto;
    padding-top: 9%;
    padding-bottom: 18%;
}
.PH-header{
    width: 100%;
    padding-left: 3%;
    padding-right: 3%;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
.PH-hero{
    display: block;
    margin:auto;
}
.PH-content{
    display: block;
    margin: auto;
    height: 600px;
}
.PH-content a:hover {
    color: #1097dd;
}
.banner-hero{
    height: 600px;
    max-width:100%;
}
.banner-logo{
    padding-top: 10%;
    max-width:100%;
    height: 200px;
}
.banner-dates {
    text-align: center;
}
.banner-dates h1{
    color: #1097dd;
    font-family: Marvel-BoldItalic !important;
    font-size: 3.0em;
    display: block;
}
.banner-dates h2{
    color: #dd8abd;
    padding-top: 5%;
    text-align: center;
    font-size: 2.0em;
}
.reg-btn {
    background-color: #dd8abd;
    color: white;
    border: none;
    display: block;
    border-radius: 38px;
    position: relative;
    font-size: 25px;
    padding: 14px;
    max-width: 300px;
    margin: 15px auto 10px;
    font-family: Marvel-Bold !important;
}
.social-media-btn{
    padding-top: 3%;
}
.social-media-btn a{
    margin-left: 10px;
}
/* ---- about ----- */
.AboutContainer {
    margin-left: auto;
    margin-right: auto;
    padding-left: 6%;
    padding-right: 6%;
    display: block;
    padding-bottom: 10%;
}
.AboutContainer h2{
    font-family: Marvel-Bold !important;
    font-size: 3em;
}
.About-content{
    font-size: 1.5em;
}
.About-header{
    padding-bottom: 8%;
    font-size: 1.5em;
}
/* ---- faqs ----- */
.FaqsContainer{
    padding-left: 6%;
    padding-right: 6%;
}
.FaqsInternal{
    width: 100%;
    overflow: hidden;
    display: flex;
}
.FaqsContainer h2{
    font-family: Marvel-Bold !important;
    font-size: 4.5em;
}
.FaqsContainer h3{
    font-family: Marvel-BoldItalic !important;
    font-size: 1.2em;
}
.FaqsCols1{
    float: left;
    width: 50%;
    padding: 10px;
    font-size: 1.5em;
}
.FaqsCols2{
    float: left;
    width: 50%;
    padding: 10px;
    font-size: 1.5em;
}

/* ---- hosted by ---- */
.HostedContainer{
    width: 100%;
    max-width: 1440px;
    display: flex;
}
.Hosted-internal{
    width: 100%;
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 10%;
    display: flex;
    font-size: 1.5em;
}
.Hosted-image{
    width: 50%;
    display: flex;
    margin:auto;
}
.Hosted-content{
    width: 50%;
    padding-right: 3%;
    margin: auto;
    height: 300px;
}
.Hosted-logo{
    height: 200px;
    max-width:100%;
    margin-left: auto;
    margin-right: auto;
}
.Hosted-content h2{
    font-size: 3em;
    font-family: Marvel-Bold !important;
}

/* ---- sponsors ---- */
.SponsorsContainer{
    display: block;
    padding-left: 6%;
    padding-right: 6%;
    padding-bottom: 10%;
}
.Sponsors-content{
    display: flex;
    flex-direction: column;
    font-size: 1.5em;
}
.Sponsors-content h2{
    font-size: 3em;
    font-family: Marvel-Bold !important;
}
.Sponsors-internal{
    margin: 25px auto 0;
    width: 95%;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
}
.Sponsors-internal div{
    width: 33%;
    text-align: center;
}
.Sponsors-internal div img{
    height: 300px;
}
/* ---- footer ----- */
.FooterContainer{
    text-align: center;
    padding-top: 10%;
    padding-bottom: 5%;
}
.Footer-internal {
    display: inline-block;
    background-color: #dd8abd;
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
}
.Footer-internal h2{
    font-size: 3em;
    font-family: Marvel-Bold !important;
} 
.Footer-link a{
    color: #1097dd;
    font-size: 1em;
}
/* ---- mobile ----- */
@media (max-width: 480px) {
    .PageHero{
        height:900px !important;
    }
    .banner-logo{
        height: 100px !important;
    }
}
@media (max-width: 848px) {
    body {
      display: block;
    }
    .HomeContainer{
        box-sizing: border-box;
    }
    .PageHero{
        height:1050px;
    }
    .PH-header{
        flex-direction: column;
        display: block;
    }
    .PH-hero{
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
    }
    .PH-content{
        margin-top: 0px;
        margin-left: auto;
        margin-right: auto;
        height: 400px
    }
    .banner-hero{
        width: 100%;
        height: 300px;
    }
    .banner-logo{
        display: block;
        margin: auto;
        height: 200px;
    }
    .banner-dates h1{
        font-size: 2.5em;
    }
    .banner-dates h2{
        font-size: 2.0em;
    }
    .reg-btn {
        font-size: 25px;
        padding: 10px;
        margin: 10px auto 10px;
    }
    .AboutContainer {
        padding-left: 6%;
        padding-right: 6%;
        font-size: .7em;
    }
    .AboutContainer h2{
        font-size: 2.25em;
    }
    .FaqsContainer{
        font-size: .7em;
    }
    .FaqsContainer h2{
        font-size: 3.377em;
    }
    .FaqsInternal{
        display: block;
    }
    .FaqsCols1{
        width: 100%;
        padding: 0px;
    }
    .FaqsCols2{
        width: 100%;
        padding: 0px;
    }
    .Hosted-internal{
        flex-direction: column;
        display: block;
        font-size: 1em;
        text-align: left;
    }
    .Hosted-content{
        height: 200px;
        width:100%;
    }
    .Hosted-image{
        width:100%;
    }
    .Hosted-logo{
        height: 150px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    .Sponsors-internal div{
        width:100%;
    }
    .Sponsors-internal div img{
        height:100px;
    }
    .Sponsors-content{
        font-size: 1em;
    }
    .Sponsors-content h2{
        font-size: 2.25em;
    }
    .Hosted-content h2{
        font-size: 2.35em;
    }
    .Footer-internal h2{
        font-size: 2em;
    }
}